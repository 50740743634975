import { Search, Icon, Swipe, SwipeItem, Tag, Grid, GridItem, Image, List } from 'vant'
import Login from '../../intercept/Login.vue'
import Share from '../../common/Share.vue'
import Loading from '../../common/Loading.vue'
import LogVisit from '../../common/LogVisit.vue'
import Call from '../../common/Call.vue'
import Clue from '../../common/Clue.vue'
import '../../../../assets/icon/font-icon.js'
export default {
    components: {
        Login: Login,
        Share: Share,
        Loading: Loading,
        LogVisit: LogVisit,
        Call: Call,
        Clue: Clue,
        [Search.name]: Search,
        [Icon.name]: Icon,
        [Swipe.name]: Swipe,
        [SwipeItem.name]: SwipeItem,
        [Tag.name]: Tag,
        [Grid.name]: Grid,
        [GridItem.name]: GridItem,
        [Image.name]: Image,
        [List.name]: List
    },
    data () {
        return {
            loadingShow: false,
            finished: false,
            keyword: '',
            classifys: [],
            classifyCode: '',
            page: { current: 0, size: 20, last: 0 },
            productList: []
        }
    },
    mounted () {
        this.retrieveDisplayClassifyList()
        this.retrieveHotProductPage()
        this.createVistClue()
        this.$refs.share.default('main')
    },
    methods: {
        async retrieveDisplayClassifyList () {
            var pd = { operatorCode: 1, type: 'HOT' }
            const { data: res } = await this.$http.post(this.BMS_URL + '/study/classify/retrieveDisplayClassifyList', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.classifys = res.data
            }
        },
        search () {
            window.sessionStorage.setItem(window.const.global.STUDY_SEARCH_INDEX_BACK, window.location.href)
            this.$router.push({ path: '/mde/study/search' })
        },
        siftSearch (condition) {
            this.classifyCode = condition.classifyCode
            this.search()
        },
        classifySearch (val) {
            window.sessionStorage.setItem(window.const.global.STUDY_SEARCH_INDEX_BACK, window.location.href)
            this.$router.push({ path: '/mde/study/search', query: { countryCode: val } })
        },
        async retrieveHotProductPage () {
            if (!this.finished) {
                this.loadingShow = true
                this.page.current = this.page.current + 1
                var pd = { type: 'HOT', operatorCode: '1', current: this.page.current, size: this.page.size }
                var { data: res } = await this.$http.post(this.BMS_URL + '/study/productAPC/retrieveDisplayProductPage', this.$qs.stringify(pd))
                if (res.status === '200') {
                    this.productList = this.productList.concat(res.data)
                    this.page.last = res.page.last
                    if (res.data.length <= 0) {
                        this.finished = true
                    }
                }
                this.loadingShow = false
            }
        },
        detail (code) {
            var key = window.const.global.STUDY_DETAIL_INDEX_BACK
            window.sessionStorage.setItem(key, window.location.href)
            this.$router.push({ path: '/mde/study/detail', query: { code: code } })
        },
        createVistClue () {
            var param = { url: window.location.href }
            var content = '出国留学首页访问'
            this.$refs.clue.createClue('STUDY_VIST_HOME', '0', JSON.stringify(param), content)
        },
        back () {
            var back = window.sessionStorage.getItem(window.const.global.STUDY_HOME_INDEX_BACK)
            if (back !== undefined && back !== null && back !== '') {
                window.location.href = back
            } else {
                this.$dialog.alert({
                    title: '提示信息',
                    message: '已返回到最上层'
                })
            }
        }
    }
}
